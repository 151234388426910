import { getShowingPricesForVintageText } from '@vivino/js-web-common';
import { CART_ITEM_SOURCE_TYPES } from 'vivino-js/context/CartItemSourceContext';
import {
  CART_ITEM_SOURCE_DATA_ATTRIBUTE,
  getCartItemSource,
  getCartItemSourceProps,
  sanitiseCartItemSource,
} from 'vivino-js/helpers/cartItemSources';
import { isVivinoPrice } from 'vivino-js/helpers/price';
import { vintageUrl } from 'vivino-js/vivinoUrls';

// The purpose of this pack is to avoid having duplicate code between
// bloonk and webpack helpers, this pack will add the vivino-js helpers
// to the window object

window.vintageLink = vintageUrl;
window.getCartItemSource = getCartItemSource;
window.getCartItemSourceProps = getCartItemSourceProps;
window.sanitiseCartItemSource = sanitiseCartItemSource;
window.CART_ITEM_SOURCE_TYPES = CART_ITEM_SOURCE_TYPES;
window.CART_ITEM_SOURCE_DATA_ATTRIBUTE = CART_ITEM_SOURCE_DATA_ATTRIBUTE;
window.isVivinoPrice = isVivinoPrice;
window.getShowingPricesForVintageText = getShowingPricesForVintageText;
